import * as React from 'react'

import withTheme from 'hoc/withTheme'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step1 ({ theme, values, isLoading, handleFieldChange }) {
    return (
        <>
            <span className={styles.headerInfo}>
                <Form.Row>
                    <Form.RowColumns>
                        <span className={styles.donationTitle}>
                            Please fill out the payment form below to complete your donation.
                        </span>
                    </Form.RowColumns>
                </Form.Row>
            </span>
            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <span className={styles.titleForm}>Credit card details</span>
                    <Form.RowColumn size={1} className={styles.row}>
                        <Form.Field
                            className={styles.field1}
                            defaultValue={values.creditDetail || null}
                            onChange={value => {
                                handleFieldChange('creditDetail', `${value}`)
                            }}
                            type='text'
                            placeholder={'Name on credit card'}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row1}>
                        <Form.Field>
                            <Form.Dropdown
                                placeholder={'Credit card type'}
                                options={['Visa']}
                                // trans={t}
                                required={false}
                                onChange={value => {
                                    handleFieldChange('cardType', `${value}`)
                                }}
                                defaultValue={values.cardType}
                            />
                        </Form.Field>
                        <Form.Field
                            defaultValue={values.creditCardNum || null}
                            onChange={value => {
                                handleFieldChange('creditCardNum', `${value}`)
                            }}
                            type='text'
                            placeholder={'Credit card number'}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field>
                            <Form.Dropdown
                                placeholder={'Exp. month'}
                                options={['Visa']}
                                // trans={t}
                                required={false}
                                onChange={value => {
                                    handleFieldChange('expMonth', `${value}`)
                                }}
                                defaultValue={values.expMonth}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                placeholder={'Exp. year'}
                                options={['2020', '2021', '2022', '2023', '2024']}
                                // trans={t}
                                required={false}
                                onChange={value => {
                                    handleFieldChange('expYear', `${value}`)
                                }}
                                defaultValue={values.expYear}
                            />
                        </Form.Field>
                        <Form.Field
                            defaultValue={values.cvvCode || null}
                            onChange={value => {
                                handleFieldChange('cvvCode', `${value}`)
                            }}
                            type='number'
                            placeholder={'CVV code'}
                        />
                    </Form.RowColumn>

                    <span className={styles.titleForm} style={{
                        marginTop: '1.8em'
                    }}>
                        Billing address
                    </span>
                    <Form.RowColumn size={1}>
                        <span className={styles.chekbox}>
                            <Form.Checkbox
                                defaultChecked={values.sameAsContact}
                                onChange={() => {
                                    handleFieldChange('sameAsContact', !values.sameAsContact)
                                }}
                            />
                            <p>Same as contact details on previous page</p>
                        </span>
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            value={values.sameAsContact ? values.addressLine1 : values.billingAddress}
                            onChange={value => {
                                handleFieldChange(values.sameAsContact ? 'addressLine1' : 'billingAddress', `${value}`)
                            }}
                            type='text'
                            placeholder={'Address'}
                        />
                        <Form.Field
                            value={values.sameAsContact ? values.addressLine2 : values.suiteUnit}
                            onChange={value => {
                                handleFieldChange(values.sameAsContact ? 'addressLine2' : 'suiteUnit', `${value}`)
                            }}
                            type='number'
                            placeholder={'Suite / Unit'}
                            required={false}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row5}>
                        <Form.Field
                            value={values.sameAsContact ? values.city : values.billingCity}
                            onChange={value => {
                                handleFieldChange(values.sameAsContact ? 'city' : 'billingCity', `${value}`)
                            }}
                            type='text'
                            placeholder={'City'}
                        />
                        <Form.Field
                            value={values.sameAsContact ? values.province : values.billingState}
                            onChange={value => {
                                handleFieldChange(values.sameAsContact ? 'province' : 'billingState', `${value}`)
                            }}
                            type='text'
                            placeholder={'State'}
                        />
                        <Form.Field
                            value={values.sameAsContact ? values.zipcode : values.billingZipCode || null}
                            onChange={value => {
                                handleFieldChange(values.sameAsContact ? 'zipcode' : 'billingZipCode', `${value}`)
                            }}
                            type='number'
                            placeholder={'Zip code'}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.Submit
                        isLoading={isLoading}
                        label='Complete donation'
                        style={{
                            background: '#55B847',
                            color: '#FFFFFF',
                            marginTop: '1.8em'
                        }}
                    />
                </Form.RowColumns>
            </Form.Row>
        </>
    )
}

Step1.defaultProps = {
    values: {
        addres: null,
        zip: null,
        city: null,
        state: null,
        suiteAptNum: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step1))
