import React from 'react'
import Footer from 'components/Footer'

import { Redirect } from 'react-router-dom'
import { updateLead } from 'Actions'
import { Store } from 'Store'

import SideBar from 'components/SideBar'
import Step1 from 'components/LeadGenForm/Step1'

import styles from './styles.module.scss'

export default ({
    // history,
    location,
    match,
    // children
}) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const firstName = state.lead.fields.firstName

    if (submitted) {
        return <Redirect to={`/${match.params.url}/payment${location.search}`} push />
    }

    return (
        <>
            <div className={styles.grid}>
                <div className={styles.leftSide}>
                    <SideBar name={firstName} />
                </div>

                <div className={styles.rightSide}>
                    <div>
                        <Step1
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: false
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                    window.scrollTo(0, 0)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done(err)
                                }
                            }}
                            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                        />
                    </div>
                    <span>
                        <Footer />
                    </span>
                </div>
            </div>
        </>
    )
}
