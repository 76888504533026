import React, { useCallback } from 'react'

import { Redirect } from 'react-router-dom'
import { Currency } from 'i18n/NumberFormatter'

import SummarySideBar from 'components/SummarySideBar'

import ModalContainer from 'components/ModalContainer'
import { updateLead } from 'Actions'
import { Store } from 'Store'
import Step2 from 'components/LeadGenForm/Step2'
import Footer from 'components/Footer'

import Button from 'components/Button'

import styles from './styles.module.scss'

import BACKGROUND from './images/background.png'
import LOGO_MODAL from './images/logo_modal.png'

export default ({
    location,
    match,
    history,
    // t
}) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [modalActive, setShowModal] = React.useState(false)
    const currencyFormatter = Currency(state.currency, state.locale)

    if (!state.lead || !state.lead.fields) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    const handleClick = useCallback(
        () => {
            history.push(`/${match.params.url}${location.search}`)
            window.scrollTo(0, 0)
        },
        [ history, location, match ]
    )

    const { donationAmount, customDonation, firstName } = state.lead.fields

    const amountToDonate =
        donationAmount === 'Other' ? customDonation : donationAmount

    React.useEffect(() => {
        if (submitted) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    }, [submitted])

    return (
        <>
            {modalActive && (
                <ModalContainer show={modalActive}>
                    <div className={styles.body}>
                        <div
                            className={styles.topImage}
                            style={{
                                backgroundImage: `url(${BACKGROUND})`
                            }}
                        >
                            <div
                                className={styles.modalLogo}
                                style={{
                                    backgroundImage: `url(${LOGO_MODAL})`
                                }}
                            />
                        </div>
                        <div className={styles.description}>
                            <h2>Thank you, {firstName}.</h2>
                            <p>
                                Your {currencyFormatter.format(amountToDonate)}{' '}
                                donation has been submitted.
                            </p>
                            <Button
                                onClick={handleClick}
                                className={styles.btn}
                            >
                                End your session
                            </Button>
                        </div>
                    </div>
                </ModalContainer>
            )}

            <div className={styles.grid}>
                <div className={styles.leftSide}>
                    <SummarySideBar
                        name={firstName}
                        amount={currencyFormatter.format(amountToDonate)}
                    />
                </div>

                <div className={styles.rightSide}>
                    <div>
                        <Step2
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: false
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                    window.scrollTo(0, 0)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done(err)
                                }
                            }}
                            initialValues={
                                state.lead && state.lead.fields
                                    ? state.lead.fields
                                    : {}
                            }
                        />
                    </div>
                    <span>
                        <Footer />
                    </span>
                </div>
            </div>
        </>
    )
}
