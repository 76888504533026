import React from 'react'

import BG from './images/bg.png'
import BG_MOBILE from './images/bg_mobile.png'

import LGGO from './images/logo.png'

import styles from './styles.module.scss'

export default function SummarySideBar ({
    amount,
    name
}) {
    return (
        <div className={styles.sideBarContainer} style={{
            '--background-image': `url(${BG})`,
            '--background-image-mobile': `url(${BG_MOBILE})`
        }}>
            <div className={styles.img} style={{
                backgroundImage: `url(${LGGO})`
            }} />

            <h2>Thank you, {name}.</h2>
            <p>With your {amount} donation, we can help make dreams come true.</p>
        </div>
    )
}
