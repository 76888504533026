import React from 'react'

import BG from './images/bg.png'
import BG_MOBILE from './images/bg_mobile.png'

import LGGO from './images/logo.png'
// import SIGNATURE from './images/signature.png'
import PLAY from './images/play.png'

import styles from './styles.module.scss'

export default function SideBar ({
    name
}) {
    return (
        <div className={styles.sideBarContainer} style={{
            '--background-image': `url(${BG})`,
            '--background-image-mobile': `url(${BG_MOBILE})`
        }}>
            <div className={styles.img} style={{
                backgroundImage: `url(${LGGO})`
            }} />

            <h2>{name}, thank <br /> you for helping us <br /> <strong>change lives.</strong></h2>

            <div className={styles.body}>
                <div className={styles.play} style={{ backgroundImage: `url(${PLAY})` }} />
            </div>
        </div>
    )
}
